
* {
    box-sizing: border-box;
    margin: 0;
}
body {
    height: 100vh;
    width: 100%;
}
#root {
    height: 100%;
    width: 100%;
}
.App {
    height: 100%;
    width: 100%;
    display: flex;
}

.side-menu {
    min-width: 220px;
}
main {
    width: calc(100% - 220px);
}

p {
    padding: 0px;
    margin: 0px;
}

/*side menu CSS*/
.side-menu {
    background-color: #16213e;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}
.side-header {
    font-size: 1.5rem;
    font-weight: 700;
}
.side-choices {
    display: flex;
    flex-direction: column;
}
.side-menu button {
    color: #aaaaaa;
    background: none;
    border: none;
    padding: 6px 5px;
    border: 1px solid transparent;
    font-size: 1rem;
}

button:hover {
    border: 1px solid;
}
.side-menu .chosen-sort {
    font-weight: 700;
    font-size: 1.1rem;
    color: #ffffff;
}
.side-bottom {
    width: 100%;
    display: flex;
    gap: 4px 8px;
    align-items: center;
    justify-content: space-between;
}
.github-logo {
    height: 35px;
    width: 35px;
}
.github-text {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
}
/*probably need to make this flex aligned right? To do so need to shove logo/text in their own container.*/
.side-bottom .min-button {
    height: 45px;
    width: 45px;
    border: .05rem solid #aaaaaa;
    border-radius: 5px;
}



/* Main display CSS */
/* main contains array-container + footer */
main {
    background-color: #ebf4f8;
}

main .array-container {
    height: 75%;
    width: 98%;
    padding-left: 2%; /*for some reason, hit screen scrolling issues with padding-bottom. Janky solution in arrbar*/
    display: flex;
    align-items: flex-end; /*forces bars to rest on the 'bottom' of the container'*/
    gap: 4px;   /*should match pixelGap in App.js*/
}
.arrBar {
    margin-bottom: 20px;
}

/*Bottom controls CSS*/
main footer {
    height: 25%;
    min-height: 100px;
    background-color: #101a34;/*aliceblue*/
    display: flex;
    justify-content: center;
    align-items: center;
}

main footer > .controls {
    background-color: aliceblue;
    width: 50%;
    min-width: 500px;
    height: 65%;
    border: 4px solid #d11141;
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.controls-buttons {
    display: flex;
    gap: 8px;
}
.controls-speed,.controls-nbars {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0px 12px;
    border-left:  3px solid #66b2b2;
    border-right: 3px solid #66b2b2;
}
.speed-slider,.nbars-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.svg-controls {
    height: 34px;
    width: 34px;
    fill: #65737e
}
.play-icon,.pause-icon,.speed-icon,.refresh-icon {
    height: 34px;
    width: 34px;
}
#pause-btn,#play-btn,#refresh-btn {
    height: 34px;
    width: 34px;
    padding: 0px;
    border: 0px;
}
input[type="range"] {
    max-width: 100px;
}
/*
.refresh-icon {
    height: 28px;
    width: 28px;
}
*/